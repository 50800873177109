<template>
  <div
    class="form-login change-pass d-flex justify-content-center align-items-center flex-column"
  >
    <div class="logo">
      <img
        v-if="logoShop"
        :src="logoShop"
        width="100%"
        height="100%"
        style="object-fit: contain; width: 100%; height: 100%"
      />
    </div>
    <div class="w-100 mb-3 px-5">
      <form v-on:submit.prevent="submitLogin(idParam)">
        <span class="title2">新しいパスワードを入力</span>
        <div v-if="!success" class="d-flex flex-column form-input">
          <input
            v-model="password"
            type="password"
            name="password"
            id="password"
            placeholder="8文字以上の英数字で新しいパスワードを入力してください"
            required
          />
          <input
            v-model="password_confirm"
            type="password"
            name="password_confirm"
            id="password_confirm"
            placeholder="8文字以上の英数字で新しいパスワードを入力してください（確認）"
            required
          />
        </div>
        <div
          v-if="success"
          class="d-flex justify-content-center align-items-center flex-column h6"
        >
          <span
            >パスワードの変更に成功しました。ログインページに戻ります。
            <router-link
              v-if="type === 'admin'"
              :to="{ name: 'login admin domain' }"
              >こちら</router-link
            >
            <router-link
              v-if="type === 'user'"
              :to="{ name: 'login shop domain' }"
              >こちら</router-link
            >
          </span>
        </div>
        <div v-if="!success" class="btn-send">
          <button
            v-on:click.prevent="changePass()"
            style="
              padding: 5px 30px !important;
              border: 1px solid #c3c4c7;
              border-radius: 8px !important;
              background: black;
              width: 80%;
            "
          >
            パスワード変更してログイン画面へ
          </button>
        </div>
      </form>
    </div>
    <!-- <div class="text-center">
      <span
        ><a href="\" style="color: black">よくあるご質問</a> &ensp;
        <a href="\" style="color: black">お問い合わせ</a> &ensp;
        <a href="\" style="color: black">会社概要</a> &ensp;
        <a href="\" style="color: black">プライバシーポリシー</a> &ensp;
        <a href="\" style="color: black">利用規約</a> &ensp;
        <a href="\" style="color: black">特定商取引法</a>
        に基づく表記</span
      >
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import no_image from "@/assets/img/logo_default.png";

export default {
  name: "ChangePassword",
  data() {
    return {
      password: "",
      password_confirm: "",
      shop_id: "",
      type: "",
      logoShop: null,
    };
  },
  computed: {
    ...mapGetters(["message", "success", "error", "ListSettingShop"]),
  },
  watch: {
    ListSettingShop() {
      this.dataShop = this.ListSettingShop;
      if (this.dataShop.length > 0) {
        if (this.dataShop[0].logo != null) {
          this.logoShop = Constants.URL_BE + "/" + this.dataShop[0].logo;
        } else {
          this.logoShop = no_image;
        }
      }
    },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["error", false]);
      }
    },
  },
  created() {
    const { query } = this.$route;
    this.shop_id = query.shop_id;
    this.type = query.type;
    this.getListSettingShopUser({
      domain: Constants.DOMAIN,
      shop_id: this.$route.params.shopId,
    });
  },
  methods: {
    ...mapActions({
      changePassword: "changePassword",
      getListSettingShopUser: "getListSettingShopUser",
    }),
    async changePass() {
      if (this.password == "") {
        this.$toasted.error("パスワードフィールドは必須です。");
      } else if (this.password_confirm == "") {
        this.$toasted.error("パスワード確認は必須なフィールドです。");
      } else if (this.password.length < 8) {
        this.$toasted.error(
          "パスワードは少なくとも8文字でなければなりません。"
        );
      } else if (this.password != this.password_confirm) {
        this.$toasted.error("パスワードの確認は一致しません。");
      } else {
        let { params } = this.$route;
        const ChangeData = {
          password: this.password,
          password_confirmation: this.password_confirm,
          token: params.token,
          shop_id: this.shop_id,
        };
        await this.changePassword(ChangeData);
      }
    },
    cancelChangePass() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
<style scoped>
.customTitleContent {
  color: #3c434a;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  line-height: 1.4;
  font-weight: bold;
  font-size: 18px;
}
.title2 {
  color: #72777c;
  width: 100%;
  text-align: center;
  font-weight: bolder;
  font-size: 18px;
  margin-top: 2em !important;
  margin-bottom: 1em !important;
  display: flex;
  align-items: center;
}
.title2::before,
.title2::after {
  border-top: 2px solid;
  content: "";
  flex-grow: 1;
}
.title2:before {
  margin-right: 0.5rem;
}
.title2:after {
  margin-left: 0.5rem;
}
</style>
